<template>
  <v-main style="margin-top:60px;">
    <v-dialog v-model="EntityProcessDialog" width="400px">
      <v-card>
        <v-card-title>
          <v-text-field v-model="NewProcessName" label=" New Process Name"/>
        </v-card-title>
        <v-card-actions>
          <v-btn color="warning" @click="CancelAddEntityProcess()">Cancel</v-btn><v-spacer></v-spacer><v-btn @click="AddEntityProcess()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h2>{{CurrentEntity.DisplayName}} - Pipelines</h2>
      <v-btn @click="AddEntityProcessDialog()" color="#eb371b" dark>Add Process</v-btn>
      <v-autocomplete @change="UpdateDefaultEntityProcess()" label="DEFAULT PROCESS" :items="EntityProcesses" item-text="Number" v-model.number="DefaultEntityProcess">
         
          <template v-slot:item="data">
             {{ data.item.Name }}
            </template>
      </v-autocomplete>
      <v-autocomplete label="Process" return-object :items="EntityProcesses" item-text="Name" v-model="editedProcess"></v-autocomplete>
      <v-select v-if="editedProcess" label="Status" :items="['Active','InActive']" v-model="editedProcess.Status"></v-select>
      <v-text-field v-model="editedProcess.DisplayName" label="Process Name"/>
            <!-- <v-autocomplete return-object :items="ProcessStages" item-text="Name" v-model="editedStage"></v-autocomplete> -->
              <v-switch v-model="processvertical" label="Vertical"></v-switch>
                <span v-if="!processvertical">
                  <v-stepper v-model="editedStage.Number" dark>

                    <v-stepper-header>
                      <span v-for="stage in editedProcess.ProcessStages" :key="stage.Number">
                        <v-stepper-step :step="stage.Number" :complete="editedStage.Number > stage.Number">{{stage.Name}}
                        </v-stepper-step>
                        <v-divider></v-divider>
                      </span>
                    </v-stepper-header>

                </v-stepper></span>
               <span v-if="editedProcess.processvertical">
                  <v-stepper v-model="editedStage.Number" dark>


                      <span v-for="stage in editedProcess.ProcessStages" :key="stage.Number">
                        <v-stepper-step :step="stage.Number" :complete="editedStage.Number > stage.Number">{{stage.Name}}
                        </v-stepper-step>
                      </span>

                </v-stepper></span>
            <br>
<v-btn @click="AddProcessStage()" color="#eb371b" dark>Add Stage</v-btn><v-btn @click="saveProcess(editedProcess)" color="green" dark>Save</v-btn>
      <v-list dense>
        <v-list-item class="basicoutlined" v-for="(stage,index) in editedProcess.ProcessStages" :key="stage.itemObjKey" :draggable="true" @dragstart="processdragStart(index, $event)" @dragend="processdragEnd()" @dragover.prevent @drop="processdragFinish(index, $event)">

          <v-list-item-content>
            <v-layout row class="justify-start"><v-icon>mdi-drag</v-icon>Stage {{index+1}} - {{stage.Name}}<v-spacer></v-spacer><span class="HasAutomations"  v-if="stage.Steps && stage.Steps.length>0"> (Has Steps)</span><span class="HasAutomations" v-if="stage.Automations.length > 0"> (Has Automations)</span></v-layout>
            </v-list-item-content>
            <v-list-item-action>
              <v-layout class="justify-end" v-on="on" @click="AssignProcessStage(stage,index)"><v-icon class="spin">mdi-cogs</v-icon></v-layout>
              </v-list-item-action>
        </v-list-item>
      </v-list>
  
      <v-dialog v-model="processdialog" max-width="800px">
          <v-card height="100%">
            <v-card-title>
                  <span class="headline">Stage {{editedStage.Number}} - {{editedStage.Name}}</span>
                </v-card-title>

            <v-card-text>
             
                    <v-tabs>
              <v-tab>General</v-tab>
                <v-tab-item>
                <v-text-field v-model="editedStage.Name"></v-text-field>
                </v-tab-item>
                <v-tab>Checklist</v-tab>
                <v-tab-item>
                  <v-btn @click="AddProcessStageSteps(editedStage)" small>Add Step</v-btn>
                 <!-- <v-layout row> -->
                   
                   <v-list dense>
                      <v-list-item  :draggable="true" @dragstart="stageStepdragStart(stepindex, $event)" @dragend="stageStepdragEnd()" @dragover.prevent @drop="stageStepdragFinish(stepindex, $event)"
                        v-for="(step,stepindex) in editedStage.Steps"
                        :key="step.Number"
                      >
                        <v-list-item-action>
                           <v-icon >mdi-drag</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>                      
                         <v-text-field class="smallinput" v-model="step.DisplayName"></v-text-field>
                        </v-list-item-content>
                        <v-list-item-content>                      
                         <v-textarea label="Caption" class="smallinput" v-model="step.Caption"></v-textarea>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon @click="deleteProcessStageStep(step)">mdi-delete-forever</v-icon>
                        </v-list-item-action>

                      </v-list-item>
                      <v-card-text v-if="editedStage && editedStage.Steps">
                          <ProcedureBuilder :ProcedureSteps="editedStage.Steps" :CanEditBuild="true" :CurrentEntity="CurrentEntity"
                          @ActivateElementDialog="ActivateElementDialog" :EditElementDialog="EditElementDialog"
                          @DeactivateElementDialog="DeactivateElementDialog" :EditedStep="EditedStep" :Record="{}"
                          @ProcessElement="ProcessElement" :ProcessStages="editedProcess.ProcessStages"
                          :EditedElement="EditedElement" :EditedElementIndex="EditedElementIndex"
                          />
                        </v-card-text>
                    </v-list>
                   <!-- </v-layout> -->
                  </v-tab-item>
              <v-tab>Automation</v-tab>
                <v-tab-item>
                  <div v-if="editedStage.Type">
                  <v-btn @click="AddAutomation(editedStage)">Add Automation</v-btn>

                 <v-layout row v-if="!editedStage.Automations">
                   <p>There are no Automations assigned to {{editedStage.Name}}.<br>Click on "Add Automation" to start adding.</p>
                   </v-layout>
                   </div>
                 <v-layout row v-else class="justify-center">
                   <v-list dense>
                      <v-list-item
                        v-for="auto in editedStage.Automations"
                        :key="auto.Number"
                        class="outline"
                      >

                        <v-list-item-content>
                          <v-list-item-title >{{auto.Name}}</v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-action>
                          <v-layout class="justify-end"><v-icon @click="editAutomation(stage, auto)" class="spin">mdi-cogs</v-icon></v-layout>
                          </v-list-item-action>

                      </v-list-item>

                    </v-list>
                   </v-layout>
                  </v-tab-item>
            </v-tabs>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeProcessDialog()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="closeProcessDialog()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="stepdialog" max-width="800px">
          <v-card width="100%">
            <v-card-title>{{editedStep.Name}}</v-card-title>
            <v-card-text>
              <v-text-field v-model="editedStep.Name"></v-text-field>
              </v-card-text>
              
            <v-card-actions>
              <v-btn @click="CancelStepEdit()">Save</v-btn>
              <v-btn @click="CancelStepEdit()">Cancel</v-btn>
            </v-card-actions>
            </v-card>
          </v-dialog>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ProcedureBuilder from '@/components/SuitePlugins/Pipelines/ProcedureBuilder';

export default {
    props: ['System','SystemEntities'],
    components: {ProcedureBuilder},
    data() {
        return {
          dragging: -1,
            NewProcessName: '',
            EntityProcessDialog: false,
            editedProcess: {ProcessStages: [],ProcessStageInteger: 1000000},
            EntityProcessInteger: 1000000,
            editedStage: {
                Name: '',
                Number: '',
            },
            editedStep: {
                Name: '',
                Number: ''
            },
            editedAutomation: {
                Name: '',
                Number: ''
            },
            Defaultstep: {
                Name: '',
                Number: ''
            },
            on: false,
            editedStepStage: {},
            stepdialog: false,
            processdialog: false,
            CurrentEntity: '',
            EntityProcesses: [],
            EditedElementIndex: -1,
            EditedElement: {Type: '',PropID: '',DisplayName: '',ID: 1000001},
            DefaultEditedElement: {Type: '',PropID: '',DisplayName: '',ID: 1000001},
            EditElementDialog: false,
            EditedStep: {},
            DefaultEntityProcess: '',
        }
    },
    computed:{
      TableConfig(){
          return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.$route.params.id)
      },
        ConfigDB(){
            return this.TableConfig.collection('EntityProcesses')
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
    },
    created(){
        if(!this.userIsAdmin){
            //reroute
        }
        else{
            this.CurrentEntity = this.SystemEntities.find(obj => obj.id === this.$route.params.id)
            this.DefaultEntityProcess = this.CurrentEntity.DefaultEntityProcess
            //this.EntityProcesses = JSON.parse(JSON.stringify(this.CurrentEntity.EntityProcesses))
            this.GetEntityProcess()
        }
        
    },
    methods:{
      UpdateDefaultEntityProcess(){        
        this.TableConfig.update({
          DefaultEntityProcess: this.DefaultEntityProcess
        })
      },
      ProcessElement(step,elmnt){
        if(this.EditedElementIndex === -1){
          step.Elements.push(elmnt)
          this.DeactivateElementDialog()
        }
        else{
          step.Elements[this.EditedElementIndex] = elmnt
          this.DeactivateElementDialog()
        }
      },
      DeactivateElementDialog(step){
        this.EditElementDialog = false
        this.EditedElement = Object.assign({},this.DefaultEditedElement)
        this.EditedElementIndex = -1
        this.EditedStep = {}
      },
      ActivateElementDialog(step,elmnt,elmntindex){
        this.EditedStep = step
          if(elmnt){
            this.EditedElementIndex = elmntindex
            this.EditedElement = elmnt
            this.EditElementDialog = true
          }
          else{
            this.EditedStep.LastElementID++
            this.EditedElementIndex = -1
            this.EditedElement = Object.assign({},this.DefaultEditedElement)
            this.EditElementDialog = true
          }
          console.log(this.EditedStep,this.EditedElementIndex)
        },
      GetEntityProcess(entityid){
        this.ConfigDB.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.EntityProcesses.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
        })
      },
      stageStepdragStart(which, ev) {
        ev.dataTransfer.setData('Text', this.id);
        ev.dataTransfer.dropEffect = 'move'
        this.dragging = which;
      },

      stageStepdragEnd(ev) {
        this.dragging = -1
      },
      stageStepdragFinish(to, ev) {
        this.stageStepprocessmoveItem(this.dragging, to);
        ev.target.style.marginTop = '2px'
        ev.target.style.marginBottom = '2px'
      },
      stageStepprocessmoveItem(from, to) {
        console.log('this.editedStage.Steps',this.editedStage.Steps)
          this.editedStage.Steps.splice(to, 0, this.editedStage.Steps.splice(from, 1)[0]);
          this.editedStage.Steps.map(step => {
            let key = this.editedStage.Steps.indexOf(step)+1
            step.Number = 1000000+key
          })
      },
      processdragStart(which, ev) {
        ev.dataTransfer.setData('Text', this.id);
        ev.dataTransfer.dropEffect = 'move'
        this.dragging = which;
      },

      processdragEnd(ev) {
        this.dragging = -1
      },
      processdragFinish(to, ev) {
        this.processmoveItem(this.dragging, to);
        ev.target.style.marginTop = '2px'
        ev.target.style.marginBottom = '2px'
      },
      
      processmoveItem(from, to) {
          this.editedProcess.ProcessStages.splice(to, 0, this.editedProcess.ProcessStages.splice(from, 1)[0]);
          this.editedProcess.ProcessStages.map(stage => {
            let key = this.editedProcess.ProcessStages.indexOf(stage)+1
            stage.Number = 1000000+key
          })
      },
        deleteProcessStageStep(step){
        let stepindex = this.editedStage.Steps.indexOf(step)
        this.editedStage.Steps.splice(stepindex,1)
        //this.editedStage.StepsInteger = this.editedStage.StepsInteger-1
        this.SaveProcessStagesFull()
        },
        closeProcessDialog() {
        this.processdialog = false
        setTimeout(() => {
            this.editedStage = {Name: 'Name', Number: '0'}
            }, 100)
        },
        AddEntityProcessDialog(){
        this.EntityProcessDialog = true
        },
        CancelAddEntityProcess(){
        this.NewProcessName = ''
        this.EntityProcessDialog = false
        },
        AddEntityProcess(){
          console.log(this.ConfigDB)
        let integer = 1000000+this.EntityProcesses.length-1+2
        let NewProcess = {Name: this.NewProcessName,DisplayName: this.NewProcessName, Number: integer, Automations: [], Type:'Process', IsHeader: true, ProcessStages: [],ProcessStageInteger: 1000000,Status: 'Active'}
        this.ConfigDB.doc(NewProcess.Number.toString()).set(NewProcess).then(doc => {
            this.CancelAddEntityProcess()
        })
        },
        AddProcessStage(){      
        console.log(typeof this.editedProcess.ProcessStageInteger, this.editedProcess.ProcessStageInteger)
        let integer = this.editedProcess.ProcessStageInteger-1+2
        console.log(typeof integer, integer)
        let length = this.editedProcess.ProcessStages.length
        let NewStage = {Name: 'Name', Number: Number(integer), Automations: [], Type:'Stage', IsHeader: true, Steps: []}
        this.editedProcess.ProcessStages.push(NewStage)
        this.editedProcess.ProcessStageInteger = Number(integer)
        console.log(this.editedProcess)
        },
        saveProcess(editedProcess){
          let editedProcessObj = Object.assign({},editedProcess)
          if(editedProcessObj.ProcessStages){
            editedProcessObj.ProcessStages = editedProcessObj.ProcessStages.map(stage => {
              stage.Steps = stage.Steps.map(step => {
              if(step.Elements){
                step.Elements = step.Elements.map(elmnt => {
                  if(elmnt.Type === 'Field'){
                    delete elmnt.FieldObj
                  }
                  return elmnt
                })
              }
              return step
            })
              return stage
            })            
          }
          console.log('editedProcessObj',editedProcessObj)
            this.ConfigDB.doc(editedProcess.id).set(editedProcessObj)
        },
        AssignProcessStage(stage,itemObjKey){
        this.editedStage = stage
        this.editedIndex = itemObjKey
        // this.editedStage = {Name: stage.Name, Number: itemObjKey+1}
        this.processdialog = true
        },
        AddProcessStageSteps(stage) {
        let step = this.editedStage.Steps.length-1+2
        if(!this.editedStage.StepsInteger || this.editedStage.StepsInteger < 1000000){
            this.editedStage.StepsInteger = 10000001
        }
        console.log(step,this.editedStage.StepsInteger)
        let stepnumber = this.editedStage.StepsInteger-1+2
        
        let NewStageStep = {
            Name: 'Stage_'+this.editedStage.Number+'Step_'+stepnumber,
            Caption: '',
            DisplayName: 'Step '+step,
            Number: stepnumber,
            Type: 'Step',
            Elements: [],
            Automations: [],
            Stage: stage.Name,
            LastElementID: 1000000,
        }
        // let stageindex = this.editedProcess.ProcessStages.indexOf(stage)
        this.editedStage.Steps.push(NewStageStep)
        this.editedStage.StepsInteger = stepnumber
        },
    }
}
</script>

<style>

</style>
